import styled from '@emotion/styled';
import { useEffect, type FC, type ReactNode } from 'react';

import { media } from '@/styles';

type Props = {
  className?: string;
  top?: ReactNode;
  html?: string;
  bottom?: ReactNode;
};

const useFixImageLoad = () => {
  // NOTE:
  // safariでgatsby-source-wordpressで、
  // editor内に埋め込まれたimageが表示されないので、
  // ここで無理やり読み込ませる。
  // もし今後治ったら、この処理は削除して良い
  // これが無くてもchromeは動作する。
  useEffect(() => {
    const inlineWPimages: Array<HTMLElement> = Array.from(
      document.querySelectorAll(`[data-wp-inline-image]`)
    );

    if (!inlineWPimages.length) {
      return;
    }

    const observers: Array<IntersectionObserver> = [];

    inlineWPimages.forEach((image) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let elem = entry.target as HTMLImageElement;
            if (!elem.src) {
              elem.src = elem.dataset.src || '';
              elem.srcset = elem.dataset.srcset || '';
            }
            observer.unobserve(elem);
          }
        });
      });
      observers.push(observer);
      observer.observe(image);
    });
    return () => {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    };
  }, []);
};

export const EntryContent: FC<Props> = ({ className, top, html, bottom }) => {
  useFixImageLoad();
  return (
    <Wrapper className={`${className ?? ''}`}>
      {top && top}
      {html && <Html dangerouslySetInnerHTML={{ __html: html }} />}
      {bottom && bottom}
    </Wrapper>
  );
};

const Html = styled.div`
  > * + * {
    &:not(li) {
      margin-top: 1.2em;
    }
  }
  b {
    font-weight: bold;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
  h1,
  h2 {
    line-height: 1.4;
    font-size: ${({ theme }) => theme.font.size.heading_2.ja.sp};
  }
  h3 {
    line-height: 1.6;
    font-size: ${({ theme }) => theme.font.size.heading_3.ja.sp};
  }
  h4,
  h5,
  h6 {
    line-height: 1.6;
  }
  h4 {
    font-size: ${({ theme }) => theme.font.size.heading_4.ja.sp};
  }
  h5 {
    font-size: ${({ theme }) => theme.font.size.heading_5.ja.sp};
  }
  h6 {
    font-size: ${({ theme }) => theme.font.size.heading_6.ja.sp};
  }
  a {
    word-break: break-all;
  }
  em {
    font-style: italic;
  }
  blockquote {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-left: 1px solid black;
    font-style: italic;
  }
  .gatsby-image-wrapper {
    &:not(.default-width) {
      width: 100% !important;
    }
  }

  :not(.default-width) {
    img {
      width: 100%;
    }
  }

  .default-width {
    img {
      object-fit: contain !important;
    }
  }

  .image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    > * {
      margin: 0 !important;
    }
  }

  iframe {
    width: 100%;
  }

  table {
    width: 100%;
  }

  ${media.ipadVerticalOrMore} {
    > * + * {
      &:not(li) {
        margin-top: 1.5em;
      }
    }
    h1,
    h2 {
      font-size: ${({ theme }) => theme.font.size.heading_2.ja.pc};
    }
    h3 {
      font-size: ${({ theme }) => theme.font.size.heading_3.ja.pc};
    }
    h4 {
      font-size: ${({ theme }) => theme.font.size.heading_4.ja.pc};
    }
    h5 {
      font-size: ${({ theme }) => theme.font.size.heading_5.ja.pc};
    }
    h6 {
      font-size: ${({ theme }) => theme.font.size.heading_6.ja.pc};
    }
  }
`;

const Wrapper = styled.div`
  > * + * {
    margin-top: 15px;
  }
  ${media.ipadVerticalOrMore} {
    max-width: ${({ theme }) => theme.structure.entry.maxWidth.pc}px;
    > * + * {
      margin-top: 30px;
    }
  }
`;

export default EntryContent;
