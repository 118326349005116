import styled from '@emotion/styled';
import useResizeObserver from '@react-hook/resize-observer';
import { useEffect, useMemo, useRef, type FC } from 'react';

import {
  usePagesContext,
  usePagesDispatchContext,
} from '@/components/common/providers/pages-provider';
import { theme } from '@/styles';
import type { SiteContentPageType } from '@/types/custom-types';
import { Page } from './page';

type Props = {
  className?: string;
  pages: SiteContentPageType[];
  currentIndex: number;
};

const useWrapperWidth = () => {
  const dispatch = usePagesDispatchContext();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    wrapperRef.current &&
      dispatch &&
      dispatch({
        type: 'set',
        payload: { width: wrapperRef.current.getBoundingClientRect().width },
      });
  }, [wrapperRef, dispatch]);

  useResizeObserver(
    wrapperRef,
    (entry) =>
      dispatch &&
      dispatch({
        type: 'set',
        payload: { width: entry.contentRect.width },
      })
  );
  return { wrapperRef };
};

let prevAnimationStyles: { x: number }[] | null = null;
type UsePageAnimationStylesProps = Pick<Props, 'pages' | 'currentIndex'>;
const usePageAnimationStyles = ({
  pages,
  currentIndex,
}: UsePageAnimationStylesProps) => {
  const { width: wrapperWidth } = usePagesContext();
  const NumberOfBasePages = 5;
  const headerWidth = theme.structure.page.headerWidth.pc;
  const contentWidth = wrapperWidth - headerWidth * NumberOfBasePages;
  return useMemo(() => {
    const animationStyles = pages.map((page, index) => {
      if (currentIndex >= 0 && index > currentIndex) {
        const x =
          index <= NumberOfBasePages
            ? headerWidth * index + contentWidth
            : headerWidth * NumberOfBasePages + contentWidth;
        return { x };
      } else {
        const x =
          index <= NumberOfBasePages
            ? headerWidth * index
            : headerWidth * NumberOfBasePages;
        return { x };
      }
    });
    const returnStyles = {
      animationStyles,
      prevAnimationStyles,
    };
    prevAnimationStyles = animationStyles;
    return returnStyles;
  }, [currentIndex, pages, headerWidth, contentWidth]);
};

export const Pages: FC<Props> = ({ className, pages, currentIndex }) => {
  const { wrapperRef } = useWrapperWidth();
  const { animationStyles, prevAnimationStyles } = usePageAnimationStyles({
    pages,
    currentIndex,
  });
  return (
    <Wrapper
      className={`${className ?? ''} current-index-${
        currentIndex >= 0 ? currentIndex : 'home'
      }`}
      ref={wrapperRef}
    >
      {pages.map(({ name, to, label, component, isSingle }, index) => (
        <StyledPage
          key={`site-content-item-${name}`}
          name={name}
          to={to}
          label={label}
          initialStyle={
            prevAnimationStyles ? prevAnimationStyles[index] : undefined
          }
          animationStyle={animationStyles ? animationStyles[index] : undefined}
          isSingle={isSingle}
        >
          {component && component}
        </StyledPage>
      ))}
    </Wrapper>
  );
};

const StyledPage = styled(Page)`
  position: absolute;
  z-index: 1;
  top: 0;
  &.page-home {
    z-index: 2;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  ${StyledPage} {
    --diff: ${({ theme }) => theme.structure.page.margin.top.pc}px;
    will-change: transform;
    &:nth-of-type(2) {
      top: calc(var(--diff));
      height: calc(100% - var(--diff));
    }
    &:nth-of-type(3) {
      top: calc(var(--diff) * 2);
      height: calc(100% - var(--diff) * 2);
    }
    &:nth-of-type(4) {
      top: calc(var(--diff) * 3);
      height: calc(100% - var(--diff) * 3);
    }
    &:nth-of-type(5) {
      top: calc(var(--diff) * 4);
      height: calc(100% - var(--diff) * 4);
    }
  }
`;

export default Pages;
