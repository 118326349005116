import styled from '@emotion/styled';
import { useCallback, useEffect, useState, type FC } from 'react';

import { useLocationContext } from '@/components/common/providers/location-provider';
import { ScreenReaderTextStyle } from '@/components/common/screen-reader-text';
import { MenuButton } from './menu-button';
import { Menus } from './menus';

type Props = {
  className?: string;
};

const useMenuButton = () => {
  const { slug, isHome } = useLocationContext();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const onClick = useCallback(() => {
    setShowMobileMenu((value) => !value);
  }, []);
  useEffect(() => {
    if (isHome) {
      setShowMobileMenu(true);
    } else {
      setShowMobileMenu(false);
    }
  }, [slug, isHome]);
  return { showMobileMenu, onClick };
};

export const MobileNavigation: FC<Props> = ({ className }) => {
  const { showMobileMenu, onClick } = useMenuButton();
  const menuListId = 'mobile-menu';
  return (
    <nav className={className ?? ''} role="navigation">
      <StyledMenuButton
        isPressed={showMobileMenu}
        onClick={onClick}
        targetId={menuListId}
      />
      <StyledMenus className={showMobileMenu ? 'shown' : ''} id={menuListId} />
    </nav>
  );
};

const StyledMenuButton = styled(MenuButton)`
  position: relative;
  top: -8px;
`;

const StyledMenus = styled(Menus)`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  &:not(.shown) {
    ${ScreenReaderTextStyle}
  }
`;

export default MobileNavigation;
