import { graphql, useStaticQuery } from 'gatsby';
import { createContext, useContext, type FC, type ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        title
        titleEn
        description
        descriptionEn
        author
        siteUrl
        twitter
        facebookAppId
        banner
      }
    }
  }
`;

type SiteMetadata = NonNullable<Queries.SeoQuery['site']>['siteMetadata'];
const SiteMetadataContext = createContext<SiteMetadata>(null);

export const SeoProvider: FC<Props> = ({ children }) => {
  const { site } = useStaticQuery<Queries.SeoQuery>(query);
  return (
    <SiteMetadataContext.Provider value={site?.siteMetadata ?? null}>
      {children}
    </SiteMetadataContext.Provider>
  );
};

export const useSiteMetadataContext = () => {
  return useContext(SiteMetadataContext);
};

export default SeoProvider;
