import { type FC } from 'react';

import { Anchor } from '@/components/common/atoms';

type Props = Pick<Queries.PageInfo, 'currentPage' | 'pageCount'> & {
  className?: string;
  archivePath: string;
};

const getElements = ({
  currentPage,
  pageCount,
  archivePath,
}: Omit<Props, 'className'>) => {
  const pageNumber = (page: number, isCurrent: boolean, isFirst: boolean) => {
    if (isCurrent) {
      return {
        isCurrent: true,
        children: page,
      };
    }

    const to = isFirst ? archivePath : `${archivePath}/page/${page}/`;
    return {
      isCurrent: false,
      children: page,
      to,
    };
  };

  const dots = {
    isCurrent: false,
    children: '…',
  };

  const elementsToRender = [];

  if (pageCount > 5) {
    // current is 1 - 3: show all on left side and dots for right
    if (currentPage < 4) {
      for (let i = 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
      }
      elementsToRender.push(dots); // dots in between
      elementsToRender.push(pageNumber(pageCount, false, false)); // last page
    }
    // if on of the last 3
    else if (currentPage >= pageCount - 2) {
      elementsToRender.push(pageNumber(1, false, true)); // last page
      elementsToRender.push(dots);

      for (let i = currentPage - 1; i < pageCount + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
      }
    } else {
      elementsToRender.push(pageNumber(1, false, true)); // last page
      elementsToRender.push(dots);

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
      }

      elementsToRender.push(dots);
      elementsToRender.push(pageNumber(pageCount, false, false)); // last page
    }
  } else {
    for (let i = 1; i < pageCount + 1; i++) {
      elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
    }
  }
  return elementsToRender;
};

export const PagesInBetween: FC<Props> = ({
  className,
  currentPage,
  pageCount,
  archivePath,
}) => {
  const elements = getElements({ currentPage, pageCount, archivePath });

  return (
    <>
      {elements.map((props, index) => {
        if ('to' in props && props.to) {
          return (
            <Anchor
              key={`pages-in-between-${index}`}
              className={className ?? ''}
              to={props.to}
            >
              {props.children}
            </Anchor>
          );
        } else {
          return (
            <span
              key={`pages-in-between-${index}`}
              className={`${className ?? ''} ${
                props?.isCurrent ? 'current' : ''
              }`}
            >
              {props.children}
            </span>
          );
        }
      })}
    </>
  );
};

export default PagesInBetween;
