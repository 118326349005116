import styled from '@emotion/styled';
import { type FC, type ReactNode } from 'react';

import { Img } from '@/components/common/atoms';
import type { Props as ImgProps } from '@/components/common/atoms/img';
import { media, theme } from '@/styles';

type Props = {
  className?: string;
  header: ReactNode;
  image?: ImgProps;
  children: ReactNode;
};

export const SingleEntry: FC<Props> = ({
  className,
  header,
  image,
  children,
}) => (
  <Wrapper className={className ?? ''}>
    <Header className={image ? 'has-image' : ''}>
      {header}
      {image && (
        <StyledImg
          {...image}
          alt={image.alt ?? 'Photo of the article'}
          sizes={`(min-width: ${theme.breakpoints.ipadVertical}px) ${
            theme.structure.entry.maxWidth.pc
          }px, calc(100vw - ${
            theme.structure.main.margin.horizontal.sp * 2
          }px - ${theme.structure.page.headerWidth.sp}px)`}
        />
      )}
    </Header>
    {children}
  </Wrapper>
);

const StyledImg = styled(Img)`
  margin-top: 10px;
  border-top: 1px solid black;
`;

const Header = styled.header`
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  &.has-image {
    padding-bottom: 0;
  }
  ${media.ipadVerticalOrMore} {
    padding-top: 10px;
    &:not(.has-image) {
      padding-bottom: 10px;
    }
  }
`;

const Wrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  ${media.ipadVerticalOrMore} {
    > * + * {
      margin-top: 30px;
    }
  }
`;

export default SingleEntry;
