import { createContext, useContext, type FC, type ReactNode } from 'react';

import type { LanguageType } from '@/types/custom-types';

type Props = {
  pathname: `/${string}`;
  language: LanguageType;
  children: ReactNode;
};

const initialValue = {
  slug: '',
  pathname: '/',
  isHome: true,
  language: 'ja' as LanguageType,
};

type LocationContextType = typeof initialValue;
const LocationContext = createContext<LocationContextType>(initialValue);

function getSlug({ pathname }: Pick<Props, 'pathname'>) {
  if (pathname === '/' || /^\/(\w{2})\/?$/.exec(pathname)) {
    return 'home';
  } else {
    return pathname.replace(/^\/?([^/]+)\/?$/, '$1');
  }
}

export const LocationProvider: FC<Props> = ({
  pathname,
  language,
  children,
}) => {
  const slug = getSlug({ pathname });
  const isHome = slug === 'home';
  return (
    <LocationContext.Provider value={{ slug, pathname, isHome, language }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);
