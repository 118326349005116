import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo, type FC } from 'react';

import { media } from '@/styles';
import { nonNullableFilter } from '@/utils';
import { Item } from './item';

type Props = {
  className?: string;
};

type StaticQueryProps = Queries.GetSnsQuery;
const staticQuery = graphql`
  query GetSns {
    wpMenu(locations: { eq: SNS }) {
      menuItems {
        nodes {
          id
          ...SnsMenuListItem
        }
      }
    }
  }
`;

const useSnsMenus = () => {
  const { wpMenu } = useStaticQuery<StaticQueryProps>(staticQuery);
  return useMemo(() => {
    if (!wpMenu?.menuItems?.nodes) {
      return null;
    }
    return wpMenu.menuItems.nodes.filter(nonNullableFilter);
  }, [wpMenu]);
};

export const SnsMenuList: FC<Props> = ({ className }) => {
  const menus = useSnsMenus();
  if (!menus) {
    return null;
  }
  return (
    <Wrapper className={className ?? ''}>
      {menus.map((menuItem) => (
        <StyledItem key={menuItem.id} {...menuItem} />
      ))}
    </Wrapper>
  );
};

const StyledItem = styled(Item)`
  ${media.lessThanSiteBreakpoint} {
    & + & {
      margin-top: 0.4em;
    }
  }
  ${media.siteBreakpointOrMore} {
    & + & {
      margin-left: 0.5em;
    }
  }
`;

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  ${media.siteBreakpointOrMore} {
    display: flex;
  }
`;

export default SnsMenuList;
