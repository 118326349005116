import { ThemeProvider } from '@emotion/react';
import { type FC, type ReactNode } from 'react';

import { theme as styleTheme } from '@/styles';
import { LanguageType, TranslationLinksType } from '@/types/custom-types';
import { LocationProvider } from './location-provider';
import { PagesProvider } from './pages-provider';
import { PeopleProvider } from './people-provider';
import { TranslationLinksProvider } from './translation-links-provider';

type Props = {
  pathname: `/${string}`;
  language: LanguageType;
  translationLinks: TranslationLinksType;
  children: ReactNode;
};

export const Providers: FC<Props> = ({
  pathname,
  language,
  translationLinks,
  children,
}) => {
  return (
    <ThemeProvider theme={styleTheme}>
      <LocationProvider pathname={pathname} language={language}>
        <TranslationLinksProvider value={translationLinks}>
          <PagesProvider>
            <PeopleProvider>{children}</PeopleProvider>
          </PagesProvider>
        </TranslationLinksProvider>
      </LocationProvider>
    </ThemeProvider>
  );
};

export default Providers;
