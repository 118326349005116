import '@emotion/react';

export const theme = {
  font: {
    family: {
      midashigo: 'a-otf-midashi-go-mb31-pr6n, sans-serif',
      chugo: 'a-otf-gothic-bbb-pr6, sans-serif',
      english: 'Lexend, "Helvetica Neue", Helvetica, sans-serif',
      main: 'Lexend, "Helvetica Neue", Helvetica, a-otf-gothic-bbb-pr6n, "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
    },
    size: {
      heading_2: {
        ja: { pc: '3.2rem', sp: '2.2rem' },
        en: { pc: '3.2rem', sp: '2.2rem' },
      },
      heading_3: {
        ja: { pc: '2.5rem', sp: '2rem' },
        en: { pc: '2.5rem', sp: '2rem' },
      },
      heading_4: {
        ja: { pc: '2.2rem', sp: '1.8rem' },
        en: { pc: '2.2rem', sp: '1.8rem' },
      },
      heading_5: {
        ja: { pc: '2rem', sp: '1.7rem' },
        en: { pc: '2rem', sp: '1.7rem' },
      },
      heading_6: {
        ja: { pc: '1.8rem', sp: '1.6rem' },
        en: { pc: '1.8rem', sp: '1.6rem' },
      },
      base: {
        ja: { pc: '1.7rem', sp: '1.5rem' },
        en: { pc: '1.7rem', sp: '1.5rem' },
      },
      xxxxLarge: { ja: '3.5rem', en: '3.5rem' },
      xxxLarge: { ja: '3.2rem', en: '3.2rem' },
      xxLarge: { ja: '2.7rem', en: '2.7rem' },
      xLarge: { ja: '2.4rem', en: '2.4rem' },
      large: { ja: '2.2rem', en: '2.2rem' },
      mediumLarge: { ja: '2rem', en: '2rem' },
      medium: { ja: '1.7rem', en: '1.7rem' },
      small: { ja: '1.4rem', en: '1.4rem' },
      xSmall: { ja: '1.2rem', en: '1.2rem' },
    },
  },
  letterSpacing: {
    ja: '0.07em',
  },
  lineHeight: {
    ja: { pc: 1.9, sp: 1.9 },
    en: { pc: 2.2, sp: 2 },
  },
  colors: {
    // bg: 'linear-gradient(to right, #f5ff00, #e7805c, #e95b7f)',
    bg: 'linear-gradient(to right, rgb(255, 254, 136), rgb(243, 169, 169), rgb(240, 113, 249))',
    font: '#000',
    gray: '#9FA0A0',
    lightGray: '#cecece',
  },
  structure: {
    main: {
      margin: {
        horizontal: {
          sp: 17,
          pc: 37,
        },
        top: {
          sp: 25,
          pc: 43,
        },
        bottom: {
          sp: 0,
          pc: 43,
        },
      },
    },
    page: {
      headerWidth: {
        sp: 43,
        pc: 80,
      },
      margin: {
        top: {
          sp: 13,
          pc: 22,
        },
      },
    },
    entry: {
      maxWidth: {
        pc: 700,
      },
    },
  },
  breakpoints: {
    siteBreakpoint: 1024,
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    ipadProHorizontal: 1300,
    large: 1440,
  },
};

type ThemeType = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemeType {}
}

export default theme;
