import styled from '@emotion/styled';
import { type FC } from 'react';

import { Anchor } from '@/components/common/atoms';
import { useLocationContext } from '@/components/common/providers/location-provider';

type Props = {
  className?: string;
};

export const Title: FC<Props> = ({ className }) => {
  const { language, isHome } = useLocationContext();
  return (
    <Wrapper className={`${className || ''}`} as={isHome ? 'h1' : 'p'}>
      <StyledAnchor to={language === 'ja' ? '/' : `/${language}/`} lang="en">
        PARADISE AIR
      </StyledAnchor>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  display: block;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const Wrapper = styled.h1`
  font-weight: normal;
`;

export default Title;
