import styled from '@emotion/styled';
import { type FC } from 'react';

import { media } from '@/styles';
import { Languages } from './languages';
import { SnsMenuList } from './sns-menu-list';

type Props = {
  className?: string;
};

export const SideMenus: FC<Props> = ({ className }) => (
  <Wrapper className={className ?? ''}>
    <SnsMenuList />
    <StyledLanguages />
  </Wrapper>
);

const StyledLanguages = styled(Languages)`
  ${media.lessThanSiteBreakpoint} {
    margin-top: 1em;
  }
  ${media.siteBreakpointOrMore} {
    margin-left: 1em;
  }
`;

const Wrapper = styled.div`
  ${media.siteBreakpointOrMore} {
    display: flex;
    align-items: center;
  }
`;

export default SideMenus;
