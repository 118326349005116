import styled from '@emotion/styled';
import { type FC } from 'react';

import { Button } from '@/components/common/atoms';

type Props = {
  className?: string;
  onClick: () => void;
  isPressed: boolean;
  targetId?: string;
  ariaLabel?: string;
};

export const MenuButton: FC<Props> = ({
  className,
  onClick,
  isPressed,
  targetId,
  ariaLabel = 'main menu',
}) => (
  <StyledMenuButton
    className={`${className ?? ''}`}
    onClick={onClick}
    aria-expanded={isPressed}
    aria-pressed={isPressed}
    aria-label={ariaLabel}
    aria-controls={targetId}
  >
    <Line />
    <Line />
    <Line />
    <Line />
    <Line />
    <Line />
  </StyledMenuButton>
);

const Line = styled.i`
  display: inline-block;
  box-sizing: border-box;
  transition: 0.08s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-color: black;
`;

const StyledMenuButton = styled(Button)`
  --diff: 5px;
  --weight: 2px;
  width: 27px;
  height: 27px;
  position: relative;

  &[aria-pressed='false'] {
    ${Line} {
      position: absolute;
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        height: var(--weight);
      }
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        width: var(--weight);
      }
      &:nth-of-type(1) {
        width: 100%;
        top: 0;
        left: 0;
      }
      &:nth-of-type(2) {
        width: calc(100% - var(--diff));
        top: calc(var(--diff));
        left: calc(var(--diff));
      }
      &:nth-of-type(3) {
        width: calc(100% - var(--diff) * 2);
        top: calc(var(--diff) * 2);
        left: calc(var(--diff) * 2);
      }
      &:nth-of-type(4) {
        height: 100%;
        top: 0;
        left: 0;
      }
      &:nth-of-type(5) {
        height: calc(100% - var(--diff));
        top: calc(var(--diff));
        left: calc(var(--diff));
      }
      &:nth-of-type(6) {
        height: calc(100% - var(--diff) * 2);
        top: calc(var(--diff) * 2);
        left: calc(var(--diff) * 2);
      }
    }
  }

  &:hover,
  &:focus-visible {
    svg {
      transform: scale(1.1);
    }
  }

  &[aria-pressed='true'] {
    ${Line} {
      position: absolute;
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: var(--weight);
      }
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        height: var(--weight);
      }
      &:nth-of-type(1) {
        height: 100%;
        top: 0;
        right: 0;
      }
      &:nth-of-type(2) {
        height: calc(100% - var(--diff));
        top: 0;
        right: calc(var(--diff));
      }
      &:nth-of-type(3) {
        height: calc(100% - var(--diff) * 2);
        top: 0;
        right: calc(var(--diff) * 2);
      }
      &:nth-of-type(4) {
        width: 100%;
        bottom: 0;
        left: 0;
      }
      &:nth-of-type(5) {
        width: calc(100% - var(--diff));
        bottom: calc(var(--diff));
        left: 0;
      }
      &:nth-of-type(6) {
        width: calc(100% - var(--diff) * 2);
        bottom: calc(var(--diff) * 2);
        left: 0;
      }
    }
  }
`;
