import { createContext, useContext, type FC, type ReactNode } from 'react';

import type { TranslationLinksType } from '@/types/custom-types';

const initialValue = {
  ja: '/',
  en: '/en/',
};

type Props = {
  value?: TranslationLinksType;
  children: ReactNode;
};

const TranslationLinksContext =
  createContext<TranslationLinksType>(initialValue);

export const TranslationLinksProvider: FC<Props> = ({ value, children }) => {
  return (
    <TranslationLinksContext.Provider value={value ?? initialValue}>
      {children}
    </TranslationLinksContext.Provider>
  );
};

export const useTranslationLinksContext = () => {
  return useContext(TranslationLinksContext);
};

export default TranslationLinksProvider;
