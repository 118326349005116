import { type FC } from 'react';

import { Anchor } from '@/components/common/atoms';

type Props = Pick<Queries.PageInfo, 'hasNextPage' | 'currentPage'> & {
  className?: string;
  archivePath: string;
};

export const Next: FC<Props> = ({
  className,
  hasNextPage,
  currentPage,
  archivePath,
}) => {
  if (!hasNextPage) {
    return null;
  }
  return (
    <Anchor
      className={className ?? ''}
      to={`${archivePath}/page/${currentPage + 1}/`}
    >
      <span aria-hidden="true">→</span>
    </Anchor>
  );
};

export default Next;
