import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { type FC } from 'react';

import { Img, Props as ImgProps } from '@/components/common/atoms/img';
import { media, theme } from '@/styles';

type Props = {
  className?: string;
};

const staticQuery = graphql`
  query HomeEntry {
    wpPage(slug: { eq: "home" }) {
      acfHome {
        homeImages {
          ...WpImg
        }
      }
    }
  }
`;

const getRandomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

let prevImage: ImgProps;
const getHomeImage = ({ wpPage }: Queries.HomeEntryQuery) => {
  if (prevImage) {
    return prevImage;
  }
  const images = wpPage?.acfHome?.homeImages;
  if (images) {
    const randomInt = getRandomInt(0, images.length - 1);
    const image = images[randomInt];
    prevImage = image;
  }
  return prevImage;
};

export const Entry: FC<Props> = ({ className }) => {
  const { wpPage } = useStaticQuery<Queries.HomeEntryQuery>(staticQuery);
  const homeImage = getHomeImage({ wpPage });
  return (
    <Wrapper className={className ?? ''}>
      <StyledImg
        {...homeImage}
        loading={'eager'}
        sizes={`(min-width: ${theme.breakpoints.ipadVertical}px) calc(100vw - ${
          theme.structure.main.margin.horizontal.pc
        }px - ${theme.structure.page.headerWidth.pc * 5}px), calc(100vw - ${
          theme.structure.main.margin.horizontal.pc
        }px)`}
      />
    </Wrapper>
  );
};

const StyledImg = styled(Img)`
  border: 1px solid black;
  height: 100%;
  ${media.lessThanSiteBreakpoint} {
    position: fixed;
    z-index: 1000;
    left: ${({ theme }) => theme.structure.main.margin.horizontal.pc}px;
    bottom: 0;
    width: calc(
      100vw - ${({ theme }) => theme.structure.main.margin.horizontal.pc}px
    );
    height: 40vh;
  }
  ${media.lessThanIpadVertical} {
    left: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
    width: calc(
      100vw - ${({ theme }) => theme.structure.main.margin.horizontal.sp}px
    );
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  ${media.siteBreakpointOrMore} {
    height: 100%;
  }
`;

export default Entry;
