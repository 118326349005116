import styled from '@emotion/styled';
import { type FC } from 'react';

import { Anchor } from '@/components/common/atoms';
import { useTranslationLinksContext } from '@/components/common/providers/translation-links-provider';
import { media } from '@/styles';

type Props = {
  className?: string;
};

export const Languages: FC<Props> = ({ className }) => {
  const { ja, en } = useTranslationLinksContext();
  return (
    <Wrapper className={className ?? ''}>
      <StyledAnchor to={ja} aria-label="Japanese">
        JA
      </StyledAnchor>
      <Slash aria-hidden={true} />
      <StyledAnchor to={en} aria-label="English">
        EN
      </StyledAnchor>
    </Wrapper>
  );
};

const Slash = styled.span`
  display: inline-block;
  background-color: black;
  ${media.lessThanSiteBreakpoint} {
    width: 18px;
    height: 1px;
    margin-top: 0.35em;
    margin-bottom: 0.35em;
  }
  ${media.siteBreakpointOrMore} {
    width: 1px;
    height: 18px;
    margin-left: 0.35em;
    margin-right: 0.35em;
  }
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  &:hover,
  &:focus-visible {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
  ${media.lessThanSiteBreakpoint} {
    text-underline-position: left;
  }
`;

const Wrapper = styled.div`
  ${media.lessThanSiteBreakpoint} {
    line-height: 1;
    writing-mode: vertical-rl;
  }
`;

export default Languages;
