import type { SiteContentPageType } from '@/types/custom-types';

export const DEFAULT_HEADER_MENUS = [
  {
    name: 'about',
    to: '/about/',
    label: 'ABOUT',
  },
];

export const DEFAULT_PAGES = [
  {
    name: 'news',
    to: '/news/',
    label: 'NEWS',
  },
  {
    name: 'program',
    to: '/program/',
    label: 'PROGRAM',
  },
  {
    name: 'opencall',
    to: '/opencall/',
    label: 'OPENCALL',
  },
  {
    name: 'activity',
    to: '/activity/',
    label: 'ACTIVITY',
  },
  {
    name: 'people',
    to: '/people/',
    label: 'PEOPLE',
  },
  {
    name: 'about',
    to: '/about/',
    label: 'ABOUT',
  },
  {
    name: 'home',
    to: '/',
    label: 'Top page',
  },
] as SiteContentPageType[];
