import styled from '@emotion/styled';
import { type FC, type ReactNode } from 'react';

import { media } from '@/styles';

type Props = {
  className?: string;
  title: string;
  children: ReactNode;
};

export const Section: FC<Props> = ({ className, title, children }) => (
  <Wrapper className={className ?? ''}>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </Wrapper>
);

const Title = styled.h2`
  line-height: 1.4;
  font-family: ${({ theme }) => theme.font.family.english};
  font-size: ${({ theme }) => theme.font.size.heading_2.en.sp};
  font-weight: normal;
  ${media.ipadVerticalOrMore} {
    font-size: ${({ theme }) => theme.font.size.heading_2.en.pc};
  }
`;
const Content = styled.div`
  margin-top: 5px;
  > * {
    border-top: 1px solid black;
  }
`;
const Wrapper = styled.section``;

export default Section;
