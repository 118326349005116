import type { LanguageType, TranslationLinksType } from '@/types/custom-types';
import { stripTag } from '@/utils';
import { graphql } from 'gatsby';
import { useEffect, type ComponentProps, type FC } from 'react';

import { useSiteMetadataContext } from '@/components/common/seo-provider';

type MetaProps = ComponentProps<'meta'>;
type Props = {
  pathname?: string;
  language: LanguageType;
  title?: string | null;
  description?: string | null;
  meta?: MetaProps[];
  image?: Queries.SeoImgFragment;
  translationLinks?: TranslationLinksType;
  isSingle?: boolean;
  firstPublicationDate?: string;
  lastPublicationDate?: string;
};

export const fragment = graphql`
  fragment SeoImg on WpMediaItem {
    sourceUrl
    width
    height
  }
`;

function useAddViewportFitCover() {
  useEffect(() => {
    // NOTE:
    // viewport-fit=coverを追加する。
    // viewportのmetaは.caches/default-html.jsに直接書かれているので、
    // 上書きできないため、ここで無理やり上書きする。
    const viewportMeta = document.head.querySelector<HTMLMetaElement>(
      'meta[name="viewport"]'
    );
    if (
      viewportMeta &&
      !viewportMeta.classList.contains('added-viewport-fit')
    ) {
      const defaultViewportContent = viewportMeta.getAttribute('content');
      if (defaultViewportContent) {
        viewportMeta.setAttribute(
          'content',
          `${defaultViewportContent}, viewport-fit=cover`
        );
        viewportMeta.classList.add('added-viewport-fit');
      }
    }
  }, []);
}

export const SEO: FC<Props> = ({
  pathname,
  language,
  title,
  description,
  meta,
  image,
  translationLinks,
}) => {
  const siteMetadata = useSiteMetadataContext();
  const defaultTitle =
    language === 'ja' ? siteMetadata?.title : siteMetadata?.titleEn;
  const pageTitle = title ? `${title} - ${defaultTitle}` : defaultTitle || '';
  const defaultDescription =
    language === 'ja' ? siteMetadata?.description : siteMetadata?.descriptionEn;
  const stripTaggedDescription = description ? stripTag(description) : null;
  const metaDescription =
    stripTaggedDescription?.replace(/\r?\n/g, '')?.substr(0, 450) ||
    defaultDescription ||
    '';
  const siteUrl = siteMetadata?.siteUrl || '';
  const url = pathname ? `${siteUrl}${pathname}` : siteUrl;
  const defaultBanner = siteMetadata?.banner || '';
  const seoImage = image?.sourceUrl
    ? `${siteUrl}${image.sourceUrl}`
    : `${siteUrl}/${defaultBanner}`;

  const alternate =
    language === 'ja'
      ? {
          hreflang: 'en',
          href:
            pathname !== '/' && translationLinks?.en === '/en/'
              ? null
              : translationLinks?.en,
        }
      : {
          hreflang: 'ja',
          href:
            pathname !== '/en/' && translationLinks?.ja === '/'
              ? null
              : translationLinks?.ja,
        };

  useAddViewportFitCover();

  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:title`,
      content: pageTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: seoImage,
    },
    {
      property: `og:image:width`,
      content: image?.width ?? 1200,
    },
    {
      property: `og:image:height`,
      content: image?.height ?? 630,
    },
    {
      property: `fb:app_id`,
      content: siteMetadata?.facebookAppId,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata?.author,
    },
    {
      name: `twitter:title`,
      content: pageTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: seoImage,
    },
  ] as MetaProps[];

  const pageMeta = meta ? [...defaultMeta, ...meta] : defaultMeta;

  // const schemaOrgJSONLD: any = [
  //   {
  //     '@context': 'http://schema.org',
  //     '@type': 'Corporation',
  //     '@id': siteUrl,
  //     url: siteUrl,
  //     name: defaultTitle,
  //     image: {
  //       '@type': 'ImageObject',
  //       url: `${siteUrl}/${defaultBanner}`,
  //     },
  //   },
  // ];

  // if (isSingle) {
  //   const author = site?.siteMetadata?.author || '@';
  //   schemaOrgJSONLD = [
  //     {
  //       '@context': 'http://schema.org',
  //       '@type': 'BlogPosting',
  //       '@id': url,
  //       url: url,
  //       name: title || '',
  //       headline: title || '',
  //       image: {
  //         '@type': 'ImageObject',
  //         url: seoImage,
  //       },
  //       description: description,
  //       datePublished: firstPublicationDate || undefined,
  //       dateModified: lastPublicationDate || undefined,
  //       author: {
  //         '@type': 'Person',
  //         name: author,
  //       },
  //       publisher: {
  //         '@type': 'Organization',
  //         name: author,
  //         logo: {
  //           '@type': 'ImageObject',
  //           url: `${siteUrl}/icons/icon-512x512.png`,
  //         },
  //       },
  //       isPartOf: siteUrl,
  //       mainEntityOfPage: {
  //         '@type': 'LocalBusiness',
  //         '@id': siteUrl,
  //       },
  //     },
  //   ];
  // }

  return (
    <>
      <html lang={language ?? 'ja'} />
      <title>{pageTitle}</title>
      {pageMeta.map((item, index) => {
        if ('name' in item) {
          return <meta key={index} name={item.name} content={item.content} />;
        } else if ('property' in item) {
          return (
            <meta key={index} property={item.property} content={item.content} />
          );
        }
      })}
      {alternate.href && (
        <link
          rel="alternate"
          hrefLang={alternate.hreflang}
          href={alternate.href}
        />
      )}
      {/* <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script> */}
    </>
  );
};

export default SEO;
