import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { useCallback, useState, type ComponentProps, type FC } from 'react';

import { nonNullableFilter } from '@/utils';

export type Props = Omit<ComponentProps<'img'>, 'width' | 'height'> &
  Queries.WpImgFragment & {
    className?: string;
  };

export const fragment = graphql`
  fragment WpImg on WpMediaItem {
    altText
    sourceUrl
    width
    height
    mimeType
    mediaDetails {
      sizes {
        name
        sourceUrl
        width
      }
    }
  }
`;

const useLoaded = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleIsLoaded = useCallback(() => {
    setIsLoaded(true);
  }, []);
  return { isLoaded, handleIsLoaded };
};

export const WpImg: FC<Props> = ({
  className,
  mediaDetails,
  altText,
  sourceUrl: fullSizeSourceUrl,
  width,
  height,
  mimeType,
  loading = 'lazy',
  ...restProps
}) => {
  const { isLoaded, handleIsLoaded } = useLoaded();
  const alt = altText || '';
  if (fullSizeSourceUrl) {
    if (mimeType === 'image/gif') {
      return (
        <StyledImgWrapper className={`${className ?? ''} image-type-gif`}>
          <StyledImg
            className={isLoaded ? 'loaded' : ''}
            src={fullSizeSourceUrl}
            alt={alt ?? ''}
            loading={loading}
            onLoad={handleIsLoaded}
          />
        </StyledImgWrapper>
      );
    } else {
      const srcsetWithoutFullSize = mediaDetails?.sizes
        ?.map((size) => {
          if (!size) return null;
          const { name, sourceUrl, width } = size;
          if (name === 'icon' || name === 'line' || name === 'calendar')
            return null;
          return `${sourceUrl} ${width}w`;
        })
        .filter(nonNullableFilter);
      const srcsetFullSize = `${fullSizeSourceUrl} ${width}w`;
      const srcset = srcsetWithoutFullSize
        ? [...srcsetWithoutFullSize, srcsetFullSize].join(', ')
        : srcsetFullSize;
      return (
        <StyledImgWrapper className={className ?? ''}>
          <StyledImg
            className={isLoaded ? 'loaded' : ''}
            src={fullSizeSourceUrl}
            srcSet={srcset}
            alt={alt}
            width={width ?? ''}
            height={height ?? ''}
            onLoad={handleIsLoaded}
            {...restProps}
          />
        </StyledImgWrapper>
      );
    }
  }
};

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &.loaded {
    opacity: 1;
  }
`;

const StyledImgWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
`;

export default WpImg;
