import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { LayoutGroup } from 'framer-motion';
import { type PageProps } from 'gatsby';
import { type FC } from 'react';

import { Providers, SiteHeader } from '@/components/common';
import { GlobalStyle, media } from '@/styles';
import { PageContextType } from '@/types/custom-types';

type Props = PageProps<unknown, PageContextType>;

const Layouts: FC<Props> = ({
  location,
  children,
  pageContext: { language, translationLinks },
}) => {
  const { pathname } = location;
  return (
    <>
      <Global styles={GlobalStyle} />
      <Providers
        pathname={pathname as `/${string}`}
        language={language}
        translationLinks={translationLinks}
      >
        <PageWrapper>
          <SiteHeader />
          <Main role="main" id="main">
            <LayoutGroup>{children}</LayoutGroup>
          </Main>
        </PageWrapper>
      </Providers>
    </>
  );
};

const Main = styled.main`
  flex-grow: 1;
  /* NOTE:
  mainの子要素にdisplay:flexがあり高さが伸びないので、
  ここでもdisplay:flexして、高さを揃える。
   */
  display: flex;
`;

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeight.ja.sp};
  background-image: ${({ theme }) => theme.colors.bg};
  font-family: ${({ theme }) => theme.font.family.main};
  font-size: ${({ theme }) => theme.font.size.base.ja.sp};
  padding-left: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
  padding-right: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
  ${media.lessThanIpadVertical} {
    min-height: 100svh;
  }
  ${media.ipadVerticalOrMore} {
    font-size: ${({ theme }) => theme.font.size.base.ja.pc};
    line-height: ${({ theme }) => theme.lineHeight.ja.pc};
    padding-left: ${({ theme }) => theme.structure.main.margin.horizontal.pc}px;
    padding-right: ${({ theme }) =>
      theme.structure.main.margin.horizontal.pc}px;
  }
  ${media.siteBreakpointOrMore} {
    overflow-x: hidden;
  }
`;

export default Layouts;
