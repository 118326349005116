import styled from '@emotion/styled';
import { useMemo, type FC } from 'react';

import { Page } from '@/components/common';
import { useLocationContext } from '@/components/common/providers/location-provider';
import { SideMenus } from '@/components/common/site-header/side-menus';
import { DEFAULT_PAGES } from '@/constants';
import { media } from '@/styles';

type Props = {
  className?: string;
};

const useMenuList = (defaultPages: typeof DEFAULT_PAGES) => {
  const { language } = useLocationContext();
  return useMemo(
    () =>
      defaultPages
        .filter((page) => page.name !== 'about' && page.name !== 'home')
        .map((menu) => {
          const { to, ...menuRest } = menu;
          return {
            to: language === 'ja' ? to : `/${language}${to}`,
            ...menuRest,
          };
        }),
    [defaultPages, language]
  );
};

export const MenuList: FC<Props> = ({ className }) => {
  const menuList = useMenuList(DEFAULT_PAGES);
  return (
    <Wrapper className={className ?? ''}>
      {menuList.map(({ name, ...rest }) => (
        <StyledListItem key={`mobile-menu-list-item-${name}`}>
          <Page {...rest} titleTag="p" />
        </StyledListItem>
      ))}
      <StyledListItem>
        <SideMenus />
      </StyledListItem>
    </Wrapper>
  );
};

const StyledListItem = styled.li`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  --topDiff: ${({ theme }) => theme.structure.page.margin.top.sp}px;
  --leftDiff: ${({ theme }) => theme.structure.page.headerWidth.sp}px;
  ${StyledListItem} {
    &:nth-of-type(2) {
      top: calc(var(--topDiff));
      left: calc(var(--leftDiff));
      height: calc(100% - var(--topDiff));
      width: calc(100% - var(--leftDiff));
    }
    &:nth-of-type(3) {
      top: calc(var(--topDiff) * 2);
      left: calc(var(--leftDiff) * 2);
      height: calc(100% - var(--topDiff) * 2);
      width: calc(100% - var(--leftDiff) * 2);
    }
    &:nth-of-type(4) {
      top: calc(var(--topDiff) * 3);
      left: calc(var(--leftDiff) * 3);
      height: calc(100% - var(--topDiff) * 3);
      width: calc(100% - var(--leftDiff) * 3);
    }
    &:nth-of-type(5) {
      top: calc(var(--topDiff) * 4);
      left: calc(var(--leftDiff) * 4);
      height: calc(100% - var(--topDiff) * 4);
      width: calc(100% - var(--leftDiff) * 4);
    }
    &:nth-of-type(6) {
      top: calc(var(--topDiff) * 5);
      left: calc(var(--leftDiff) * 5);
      height: calc(100% - var(--topDiff) * 5);
      width: calc(100% - var(--leftDiff) * 5);
      box-shadow:
        1px 1px 0px 0px black inset,
        0px -1px 0px 0px black inset;
      padding-top: ${({ theme }) => theme.structure.page.margin.top.sp}px;
      padding-left: 10px;
    }
  }
  ${media.ipadVerticalOrMore} {
    --topDiff: ${({ theme }) => theme.structure.page.margin.top.pc}px;
    --leftDiff: ${({ theme }) => theme.structure.page.headerWidth.pc}px;
    ${StyledListItem} {
      &:nth-of-type(6) {
        padding-top: ${({ theme }) => theme.structure.page.margin.top.pc}px;
        padding-left: 20px;
      }
    }
  }
`;

export default MenuList;
