import styled from '@emotion/styled';
import { forwardRef, type ComponentProps } from 'react';

type Props = ComponentProps<'input'> & {
  className?: string;
  label?: string;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ className, label, ...rest }, ref) => (
    <Wrapper className={className ?? ''}>
      <Input ref={ref} type="checkbox" {...rest} />
      {label && <span>{label}</span>}
    </Wrapper>
  )
);

Checkbox.displayName = 'Checkbox';

const Input = styled.input`
  height: 0.9em;
  width: 0.9em;
  position: relative;
  border: 1px solid black;
  margin: 0;
  margin-right: 0.3em;
  &::after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
  }
  &:checked {
    &::after {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.label`
  line-height: 1.7;
  > * {
    vertical-align: middle;
    line-height: 1.2;
  }
`;

export default Checkbox;
