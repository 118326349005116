import styled from '@emotion/styled';
import { type FC } from 'react';

import { media } from '@/styles';
import { useIsMobile, useIsMounted } from '@/utils';
import { HeaderNavigation } from './header-navigation';
import { MobileNavigation } from './mobile-navigation';
import { SideMenus } from './side-menus';
import { Title } from './title';

type Props = {
  className?: string;
};

export const SiteHeader: FC<Props> = ({ className }) => {
  const isMounted = useIsMounted();
  const isMobile = useIsMobile();
  return (
    <Wrapper className={className || ''} role="banner">
      <TitleWrapper>
        <StyledTitle />
        <StyledHeaderNavigation />
      </TitleWrapper>
      {isMounted && (
        <div>
          {!isMobile && <SideMenus />}
          {isMobile && <MobileNavigation />}
        </div>
      )}
    </Wrapper>
  );
};

const StyledTitle = styled(Title)``;

const StyledHeaderNavigation = styled(HeaderNavigation)`
  margin-left: 2em;
`;

const TitleWrapper = styled.div`
  display: flex;
`;
const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  font-size: ${({ theme }) => theme.font.size.mediumLarge.ja};
  font-weight: normal;
  padding-top: 20px;
  padding-bottom: 10px;
  ${media.ipadVerticalOrMore} {
    font-size: ${({ theme }) => theme.font.size.xLarge.ja};
    padding-bottom: 10px;
  }
  ${media.lessThanSiteBreakpoint} {
    position: sticky;
    z-index: 100;
    top: 0px;
  }
`;

export default SiteHeader;
