import { useMemo, type FC } from 'react';

import { Page } from '@/components/common';
import { DEFAULT_PAGES } from '@/constants';
import type { SiteContentCurrentType } from '@/types/custom-types';

type Props = {
  className?: string;
  current?: SiteContentCurrentType;
};

const usePage = (current: Props['current']) =>
  useMemo(() => {
    if (!current) {
      return null;
    }
    const targetPage = DEFAULT_PAGES.find(
      (page) => page.name === current?.name
    );
    if (!targetPage) {
      return null;
    }
    return {
      label: targetPage.label,
      component: current.component,
      isSingle: current?.isSingle,
    };
  }, [current]);

export const Mobile: FC<Props> = ({ className, current }) => {
  const page = usePage(current);
  if (!page) {
    return null;
  }
  return (
    <Page
      className={className ?? ''}
      label={page.label}
      titleTag={page.isSingle ? 'p' : 'h1'}
    >
      {page.component && page.component}
    </Page>
  );
};

export default Mobile;
