import { type FC } from 'react';

import { Anchor } from '@/components/common/atoms';

type Props = Pick<Queries.PageInfo, 'hasPreviousPage' | 'currentPage'> & {
  className?: string;
  archivePath: string;
};

const getPreviousLink = ({
  currentPage,
  archivePath,
}: Pick<Props, 'currentPage' | 'archivePath'>) => {
  let previousLink: string | null = null;
  if (2 === currentPage) {
    previousLink = archivePath;
  } else if (2 < currentPage) {
    previousLink = `${archivePath}/page/${currentPage - 1}/`;
  }
  return previousLink;
};

export const Previous: FC<Props> = ({
  className,
  hasPreviousPage,
  currentPage,
  archivePath,
}) => {
  if (!hasPreviousPage) {
    return null;
  }
  const previousLink = getPreviousLink({ currentPage, archivePath });
  if (!previousLink) {
    return null;
  }

  return (
    <Anchor className={className ?? ''} to={previousLink}>
      <span aria-hidden="true">←</span>
    </Anchor>
  );
};

export default Previous;
