import {
  createContext,
  useContext,
  useReducer,
  type Dispatch,
  type FC,
  type ReactNode,
  type Reducer,
} from 'react';

const initialValue = {
  width: 0,
};

type Props = {
  children: ReactNode;
};
type PagesContextType = typeof initialValue;
type ReducerType = { type: string; payload?: PagesContextType };
type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

const PagesContext = createContext<PagesContextType>(initialValue);
const PagesDispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});
const pagesReducer: Reducer<PagesContextType, ReducerType> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('PagesReducer error');
  }
};
export const PagesProvider: FC<Props> = ({ children }) => {
  const [pagesState, pagesDispatch] = useReducer(pagesReducer, initialValue);
  return (
    <PagesContext.Provider value={pagesState}>
      <PagesDispatchContext.Provider value={{ dispatch: pagesDispatch }}>
        {children}
      </PagesDispatchContext.Provider>
    </PagesContext.Provider>
  );
};

export const usePagesContext = () => {
  return useContext(PagesContext);
};

export const usePagesDispatchContext = () => {
  const { dispatch } = useContext(PagesDispatchContext);
  return dispatch;
};

export default PagesProvider;
