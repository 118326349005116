// https://github.com/styled-components/styled-components/blob/master/packages/styled-components/docs/tips-and-tricks.md#more-powerful-example

//github.com/morajabi/styled-media-query/blob/master/src/index.d.ts

import { theme } from './theme';

const { breakpoints } = theme;
export const media = Object.entries(breakpoints)
  .map((item) => item)
  .reduce(
    (prev, [key, breakpoint]) => {
      prev[
        `greaterThan${key.charAt(0).toUpperCase() + key.slice(1)}`
      ] = `@media (min-width: ${breakpoint + 1}px)`;
      prev[`${key}OrMore`] = `@media (min-width: ${breakpoint}px)`;
      prev[`${key}OrLess`] = `@media (max-width: ${breakpoint}px)`;
      prev[
        `lessThan${key.charAt(0).toUpperCase() + key.slice(1)}`
      ] = `@media (max-width: ${breakpoint - 1}px)`;
      return prev;
    },
    {} as { [index: string]: string }
  );
