import { type FC } from 'react';

import { Sharp, Props as SharpProps } from './sharp';
import { WpImg, Props as WpImgProps } from './wp-img';

export type Props = (WpImgProps | SharpProps) | null;

export const Img: FC<Props> = (props) => {
  if (!props) return null;

  if ('imageFile' in props) {
    return <Sharp {...props} />;
  } else {
    if ('mediaDetails' in props) {
      return <WpImg {...props} />;
    }
  }
};

export default Img;
