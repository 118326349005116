exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/news-single.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-opencall-archive-tsx": () => import("./../../../src/templates/opencall-archive.tsx" /* webpackChunkName: "component---src-templates-opencall-archive-tsx" */),
  "component---src-templates-opencall-single-tsx": () => import("./../../../src/templates/opencall-single.tsx" /* webpackChunkName: "component---src-templates-opencall-single-tsx" */),
  "component---src-templates-people-archive-tsx": () => import("./../../../src/templates/people-archive.tsx" /* webpackChunkName: "component---src-templates-people-archive-tsx" */),
  "component---src-templates-people-single-tsx": () => import("./../../../src/templates/people-single.tsx" /* webpackChunkName: "component---src-templates-people-single-tsx" */),
  "component---src-templates-program-archive-tsx": () => import("./../../../src/templates/program-archive.tsx" /* webpackChunkName: "component---src-templates-program-archive-tsx" */),
  "component---src-templates-program-single-tsx": () => import("./../../../src/templates/program-single.tsx" /* webpackChunkName: "component---src-templates-program-single-tsx" */)
}

