import styled from '@emotion/styled';
import { type FC } from 'react';

import { Anchor } from '@/components/common/atoms';
import type { HeaderMenuType } from '@/types/custom-types';

export type Props = HeaderMenuType & {
  className?: string;
};

export const Item: FC<Props> = ({ className, label, to }) => {
  if (!to) {
    return null;
  }
  return (
    <li className={className || ''}>
      <StyledAnchor to={to} activeClassName={'current'}>
        {label}
      </StyledAnchor>
    </li>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  display: inline-block;
  &:hover,
  &:focus {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
`;

export default Item;
