import { css } from '@emotion/react';
import reset from 'emotion-reset';

import { media } from './media';
import { theme } from './theme';

export const GlobalStyle = css`
  ${reset}
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  ::selection {
    background: ${theme.colors.lightGray};
  }

  html {
    font-size: 62.5%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: ${theme.font.family.main};
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border-radius: 0;
    line-height: ${theme.lineHeight.ja.pc};
    font-family: inherit;
    font-size: inherit;
    border: 1px solid black;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: 1px solid black;
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 1.5em;
  }

  table {
    border-collapse: collapse;
    line-height: 1.6;
    th,
    td {
      padding: 0.5em 1em;
      border: 1px solid black;
    }
    tr > td:first-of-type {
      min-width: 6.5em;
    }
  }
  ${media.ipadVerticalOrMore} {
    table {
      tr > td:first-of-type {
        min-width: 150px;
      }
    }
  }
`;

export default GlobalStyle;
