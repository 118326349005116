import styled from '@emotion/styled';
import { type FC } from 'react';

import { media } from '@/styles';
import { MenuList } from './menu-list';

type Props = {
  className?: string;
  id: string;
};

export const Menus: FC<Props> = ({ className, id }) => (
  <Wrapper className={className ?? ''} id={id}>
    <MenuList />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ theme }) => theme.colors.bg};
  padding-top: 62px;
  padding-left: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
  ${media.ipadVerticalOrMore} {
    padding-top: 65px;
    padding-left: ${({ theme }) => theme.structure.main.margin.horizontal.pc}px;
  }
`;

export default Menus;
