import { type FC } from 'react';

import type { SiteContentCurrentType } from '@/types/custom-types';
import { useIsMobile, useIsMounted } from '@/utils';
import { Mobile } from './mobile';
import { Pc } from './pc';

type Props = {
  className?: string;
  current?: SiteContentCurrentType;
};

export const SiteContent: FC<Props> = (props) => {
  const isMounted = useIsMounted();
  const isMobile = useIsMobile();
  if (isMounted) {
    if (isMobile) {
      return <Mobile {...props} />;
    } else {
      return <Pc {...props} />;
    }
  }
  return null;
};

export default SiteContent;
