import { useMemo, type FC } from 'react';

import { useLocationContext } from '@/components/common/providers/location-provider';
import { DEFAULT_PAGES } from '@/constants';
import type { SiteContentCurrentType } from '@/types/custom-types';
import { Pages } from './pages';

type Props = {
  className?: string;
  current?: SiteContentCurrentType;
};

type UsePagesProps = {
  defaultPages: typeof DEFAULT_PAGES;
  current?: Props['current'];
};
const usePages = ({ defaultPages, current }: UsePagesProps) => {
  const { language } = useLocationContext();
  return useMemo(() => {
    const pages = defaultPages.map((page) => {
      const { to, ...pageRest } = page;
      const toWithLanguage = language === 'ja' ? to : `/${language}${to}`;
      if (current && current.name === page.name) {
        return {
          to: toWithLanguage,
          component: current.component,
          isSingle: current.isSingle,
          ...pageRest,
        };
      }
      return { to: toWithLanguage, ...pageRest };
    });
    return {
      pages,
      currentIndex: pages.findIndex((page) => page?.component),
    };
  }, [defaultPages, language, current]);
};

export const Pc: FC<Props> = ({ className, current }) => {
  const { pages, currentIndex } = usePages({
    defaultPages: DEFAULT_PAGES,
    current,
  });
  return (
    <Pages className={className} pages={pages} currentIndex={currentIndex} />
  );
};

export default Pc;
