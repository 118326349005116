import {
  GatsbyImage,
  getImage,
  type GatsbyImageProps,
} from 'gatsby-plugin-image';
import { useCallback, useMemo, useState, type FC } from 'react';

export type Props = Omit<GatsbyImageProps, 'image' | 'alt'> & {
  className?: string;
  imageFile?: {
    childImageSharp: Queries.Maybe<Pick<Queries.ImageSharp, 'gatsbyImageData'>>;
  } | null;
  alt?: string;
};

const useAspectRatioClass = (image?: GatsbyImageProps['image'] | null) =>
  useMemo(() => {
    if (!image) {
      return null;
    }
    const aspectRatio = image.width / image.height;
    return aspectRatio > 1
      ? 'horizontal'
      : aspectRatio === 1
      ? 'square'
      : 'vertical';
  }, [image]);

type GetImageArgProps = Parameters<typeof getImage>[0];
const getImageData = (imageFile: Props['imageFile']) => {
  if (!imageFile) {
    return null;
  }
  // NOTE:
  // getImageの引数は、FileNodeなのだが、
  // gatsby-plugin-image側で定義されているFileNode内のNodeのid、parentなどが必須になっているので、無視して渡すようにする。
  const { childImageSharp } = imageFile;
  return getImage({ childImageSharp } as GetImageArgProps);
};

export const Sharp: FC<Props> = ({
  className,
  loading = 'lazy',
  onLoad,
  imageFile,
  alt = '',
  ...restProps
}) => {
  const [loaded, setLoaded] = useState(false);
  const onImgLoad = useCallback(() => {
    // NOTE:
    // gatsby imageのonLoadは2回目（一度読み込まれた画像で、ページ遷移後にもう一度表示する画像）は動作しない可能性がある？
    // 要検証
    setLoaded(true);
    onLoad && onLoad({ wasCached: true });
  }, [onLoad]);

  const image = getImageData(imageFile);
  const aspectRatioClass = useAspectRatioClass(image);
  if (!imageFile || !image) {
    return null;
  }
  return (
    <div
      className={`${className || ''} image-type-sharp ${
        loaded ? 'loaded' : ''
      } ${aspectRatioClass ?? ''}`}
    >
      <GatsbyImage
        image={image}
        loading={loading}
        onLoad={onImgLoad}
        alt={alt}
        {...restProps}
      />
    </div>
  );
};

export default Sharp;
