import {
  createContext,
  useContext,
  useReducer,
  type Dispatch,
  type FC,
  type ReactNode,
  type Reducer,
} from 'react';

const initialValue = {};

type Props = {
  children: ReactNode;
};
type PeopleArchiveNodes = Queries.PeopleArchiveEntriesFragment['nodes'];
export type PeopleAllType = {
  inResident?: PeopleArchiveNodes;
  nextResident?: PeopleArchiveNodes;
  restNodes?: PeopleArchiveNodes;
};
type PeopleContextType = {
  ja?: PeopleAllType;
  en?: PeopleAllType;
};
type ReducerType = { type: string; payload?: PeopleContextType };
type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

const PeopleContext = createContext<PeopleContextType>(initialValue);
const PeopleDispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});
const peopleReducer: Reducer<PeopleContextType, ReducerType> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('PeopleReducer error');
  }
};

export const PeopleProvider: FC<Props> = ({ children }) => {
  const [peopleState, peopleDispatch] = useReducer(peopleReducer, initialValue);
  return (
    <PeopleContext.Provider value={peopleState}>
      <PeopleDispatchContext.Provider value={{ dispatch: peopleDispatch }}>
        {children}
      </PeopleDispatchContext.Provider>
    </PeopleContext.Provider>
  );
};

export const usePeopleContext = () => {
  return useContext(PeopleContext);
};

export const usePeopleDispatchContext = () => {
  const { dispatch } = useContext(PeopleDispatchContext);
  return dispatch;
};

export default PeopleProvider;
