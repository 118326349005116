import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GatsbyLinkProps, Link } from 'gatsby';
import { type FC, type ReactNode } from 'react';

type Props = GatsbyLinkProps<unknown> & {
  className?: string;
  children: ReactNode;
};

export const Anchor: FC<Props> = ({
  className,
  to,
  children,
  ['aria-label']: ariaLabel,
  activeClassName = 'current',
  partiallyActive = false,
}) => {
  const isInternal = to?.startsWith('/');
  if (isInternal) {
    return (
      <StyledLink
        className={className || ''}
        to={to?.endsWith('/') ? to : `${to}/`}
        aria-label={ariaLabel || ''}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
      >
        {children}
      </StyledLink>
    );
  } else {
    return (
      <StyledA
        className={className || ''}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLabel || ''}
      >
        {children}
      </StyledA>
    );
  }
};

const BaseStyle = css``;

const StyledLink = styled(Link)`
  ${BaseStyle}
`;

const StyledA = styled.a`
  ${BaseStyle}
`;

export default Anchor;
