import { useMemo, type FC } from 'react';

import { useLocationContext } from '@/components/common/providers/location-provider';
import { DEFAULT_HEADER_MENUS } from '@/constants';
import { MenuList } from './menu-list';

type Props = {
  className?: string;
};

const useMenus = (defaultMenus: typeof DEFAULT_HEADER_MENUS) => {
  const { language } = useLocationContext();
  return useMemo(
    () =>
      defaultMenus.map((menu) => {
        const { to, ...menuRest } = menu;
        return {
          to: language === 'ja' ? to : `/${language}${to}`,
          ...menuRest,
        };
      }),
    [defaultMenus, language]
  );
};

export const HeaderNavigation: FC<Props> = ({ className }) => {
  const menus = useMenus(DEFAULT_HEADER_MENUS);
  return (
    <nav role="navigation" className={className || ''}>
      <MenuList menus={menus} />
    </nav>
  );
};

export default HeaderNavigation;
