import styled from '@emotion/styled';
import {
  faFacebook,
  faInstagram,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import { useMemo, type FC } from 'react';

import { ScreenReaderText } from '@/components/common';
import { Anchor } from '@/components/common/atoms';
import { media } from '@/styles';

type Props = Queries.SnsMenuListItemFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment SnsMenuListItem on WpMenuItem {
    label
    url
    cssClasses
  }
`;

const useIcon = ({ cssClasses }: Pick<Props, 'cssClasses'>) =>
  useMemo(() => {
    if (!cssClasses) {
      return null;
    }
    const cssClassesString = cssClasses.join(' ');

    if (/facebook/.exec(cssClassesString)) {
      return <FontAwesomeIcon icon={faFacebook} />;
    } else if (/twitter/.exec(cssClassesString)) {
      return <FontAwesomeIcon icon={faTwitterSquare} />;
    } else if (/instagram/.exec(cssClassesString)) {
      return <FontAwesomeIcon icon={faInstagram} />;
    }
    return null;
  }, [cssClasses]);

export const Item: FC<Props> = ({ className, label, url, cssClasses }) => {
  const icon = useIcon({ cssClasses });
  if (!icon) {
    return null;
  }
  if (!url) {
    return null;
  }
  return (
    <li className={className ?? ''}>
      <StyledAnchor className={cssClasses?.join(' ')} to={url}>
        {icon}
        <ScreenReaderText>{label}</ScreenReaderText>
      </StyledAnchor>
    </li>
  );
};

const StyledAnchor = styled(Anchor)`
  &:hover,
  &:focus-visible {
    opacity: 0.5;
  }
  &.facebook-link {
    font-size: 1.1em;
  }
  &.twitter-link {
    font-size: 1.2em;
  }
  &.instagram-link {
    font-size: 1.25em;
  }
  ${media.ipadVerticalOrMore} {
    &.facebook-link {
      font-size: 1.6em;
    }
    &.twitter-link {
      font-size: 1.7em;
    }
    &.instagram-link {
      font-size: 1.8em;
    }
  }
  ${media.siteBreakpointOrMore} {
    &.facebook-link {
      font-size: 1.15em;
    }
    &.twitter-link {
      font-size: 1.2em;
    }
    &.instagram-link {
      font-size: 1.22em;
    }
  }
`;

export default Item;
