import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useCallback, useState, type FC, type ReactNode } from 'react';

import { Button } from '@/components/common/atoms';
import { media } from '@/styles';

type Props = {
  className?: string;
  defaultHasShown?: boolean;
  children?: ReactNode;
};

const useHasShownFilters = (defaultHasShown: boolean) => {
  const [hasShown, setHasShown] = useState(defaultHasShown);
  const showFilters = useCallback(() => {
    setHasShown && setHasShown((value) => !value);
  }, []);
  const shownVariants = {
    open: {
      height: 'auto',
    },
    collapsed: { height: 0 },
  };
  return { hasShown, showFilters, shownVariants };
};

export const Search: FC<Props> = ({
  className,
  defaultHasShown = false,
  children,
}) => {
  const { hasShown, showFilters, shownVariants } =
    useHasShownFilters(defaultHasShown);
  return (
    <Wrapper className={className ?? ''}>
      <Title>
        <StyledButton onClick={showFilters}>SEARCH</StyledButton>
      </Title>
      <ContentWrapper
        initial={hasShown ? 'open' : 'collapsed'}
        animate={hasShown ? 'open' : 'collapsed'}
        inherit={false}
        variants={shownVariants}
        transition={{
          duration: 0.3,
        }}
      >
        <Content>{children && children}</Content>
      </ContentWrapper>
    </Wrapper>
  );
};

const StyledButton = styled(Button)`
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  width: 100%;
  text-align: left;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.font.size.xLarge.ja};
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  line-height: 1;
  ${media.ipadVerticalOrMore} {
    font-size: ${({ theme }) => theme.font.size.xxxLarge.ja};
  }
`;

const Content = styled.div`
  margin-top: 20px;
  ${media.ipadVerticalOrMore} {
    margin-top: 40px;
  }
`;

const ContentWrapper = styled(motion.div)`
  overflow: hidden;
`;

const Wrapper = styled.aside`
  padding-bottom: 60px;
  ${media.ipadVerticalOrMore} {
    padding-bottom: 90px;
  }
`;

export default Search;
