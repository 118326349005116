import { media } from '@/styles';
import { type FC, type ReactNode } from 'react';

import styled from '@emotion/styled';
import { Pagination } from './pagination';

type Props = {
  children?: ReactNode;
  pageInfo?: Queries.PaginationFragment;
  archivePath?: string;
};

export const Entries: FC<Props> = ({ children, pageInfo, archivePath }) => (
  <>
    {children}
    {pageInfo && archivePath && (
      <StyledPagination archivePath={archivePath} {...pageInfo} />
    )}
  </>
);

const StyledPagination = styled(Pagination)`
  margin-top: 20px;
  ${media.ipadVerticalOrMore} {
    margin-top: 40px;
  }
`;

export default Entries;
