import styled from '@emotion/styled';
import {
  useCallback,
  useEffect,
  useRef,
  type FC,
  type ReactNode,
  type UIEventHandler,
} from 'react';

import { Anchor } from '@/components/common/atoms';
import { useLocationContext } from '@/components/common/providers/location-provider';
import { media } from '@/styles';

type Props = {
  className?: string;
  children?: ReactNode;
  name?: string;
  to?: string;
  label: string;
  titleTag: 'h1' | 'p';
  isCurrent?: boolean;
};

const useScrollTop = (isCurrent: Props['isCurrent']) => {
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isCurrent) {
      if (contentRef.current && history.state?.scrollTop) {
        contentRef.current.scrollTop = history.state?.scrollTop;
      }
    }
  }, [isCurrent, contentRef]);

  const id = useRef<NodeJS.Timeout>();
  const onScroll = useCallback<UIEventHandler<HTMLDivElement>>((event) => {
    const currentTarget = event.currentTarget;
    if (id.current) {
      clearTimeout(id.current);
    }
    id.current = setTimeout(() => {
      history.replaceState(
        {
          ...history.state,
          ...{ scrollTop: currentTarget?.scrollTop ?? 0 },
        },
        ''
      );
    }, 500);
  }, []);
  return { contentRef, onScroll };
};

export const Page: FC<Props> = ({
  className,
  children,
  name,
  to,
  label,
  titleTag,
  isCurrent = false,
}) => {
  const { language } = useLocationContext();
  const { contentRef, onScroll } = useScrollTop(isCurrent);
  return (
    <Wrapper className={className ?? ''}>
      <InnerWidth className={name === 'about' ? 'about' : ''}>
        <Header>
          <Label as={titleTag}>
            {to && <StyledAnchor to={to}>{label}</StyledAnchor>}
            {!to && label}
          </Label>
        </Header>
        <Content ref={contentRef} onScroll={isCurrent ? onScroll : undefined}>
          {children && (
            <ContentInner className={`language-${language}`}>
              {children}
            </ContentInner>
          )}
        </Content>
      </InnerWidth>
    </Wrapper>
  );
};

const Label = styled.p`
  line-height: 1.23;
  writing-mode: vertical-rl;
  font-family: ${({ theme }) => theme.font.family.english};
  font-size: ${({ theme }) => theme.structure.page.headerWidth.sp * 0.7}px;
  width: 100%;
  ${media.ipadVerticalOrMore} {
    font-size: ${({ theme }) => theme.structure.page.headerWidth.pc * 0.8}px;
  }
`;

const StyledAnchor = styled(Anchor)`
  display: block;
  height: 100%;
  text-decoration: none;
  font-weight: normal;
`;

const Header = styled.div`
  padding-top: ${({ theme }) => theme.structure.page.margin.top.sp}px;
  width: ${({ theme }) => theme.structure.page.headerWidth.sp}px;
  ${media.ipadVerticalOrMore} {
    line-height: 1;
    padding-top: ${({ theme }) => theme.structure.page.margin.top.pc}px;
    width: ${({ theme }) => theme.structure.page.headerWidth.pc}px;
    transform: translateX(0) scale(1) !important;
  }
`;

const ContentInner = styled.div`
  padding-left: 10px;
  padding-bottom: 75px;
  &.language-ja {
    font-feature-settings: 'palt';
    letter-spacing: ${({ theme }) => theme.letterSpacing.ja};
  }
  ${media.ipadVerticalOrMore} {
    padding-left: 40px;
  }
  ${media.siteBreakpointOrMore} {
    padding-left: 60px;
    padding-right: 23px;
    padding-bottom: 150px;
  }
  ${media.lessThanSiteBreakpoint} {
    min-height: 40vh;
  }
`;

const Content = styled.div`
  padding-top: 16px;
  width: calc(100% - ${({ theme }) => theme.structure.page.headerWidth.sp}px);
  &::-webkit-scrollbar {
    width: 1px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: black;
  }
  ${media.ipadVerticalOrMore} {
    width: calc(100% - ${({ theme }) => theme.structure.page.headerWidth.pc}px);
    margin-top: ${({ theme }) => theme.structure.page.margin.top.pc}px;
    padding-top: 52px;
  }
  ${media.siteBreakpointOrMore} {
    overflow-x: hidden;
    width: calc(
      100% - ${({ theme }) => theme.structure.page.headerWidth.pc}px -
        ${({ theme }) => theme.structure.main.margin.horizontal.pc}px
    );
  }
`;

const InnerWidth = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  ${media.siteBreakpointOrMore} {
    width: calc(
      100% - ${({ theme }) => theme.structure.main.margin.horizontal.pc}px -
        ${({ theme }) => theme.structure.page.headerWidth.pc * 4}px
    );
    /* NOTE:
    homeとaboutは、headerWidth.pc * 5
    他のページは、headerWidth.pc * 4
     */
    &.about {
      width: calc(
        100% - ${({ theme }) => theme.structure.main.margin.horizontal.pc}px -
          ${({ theme }) => theme.structure.page.headerWidth.pc * 5}px
      );
    }
  }
`;

const Wrapper = styled.div`
  background-color: white;
  box-shadow:
    1px 1px 0px 0px black inset,
    0px -1px 0px 0px black inset;
  height: 100%;
  width: calc(
    100% + ${({ theme }) => theme.structure.main.margin.horizontal.sp}px
  );
  padding-right: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
  margin-right: -${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
  ${media.ipadVerticalOrMore} {
    width: calc(
      100% + ${({ theme }) => theme.structure.main.margin.horizontal.pc}px
    );
    padding-right: ${({ theme }) =>
      theme.structure.main.margin.horizontal.pc}px;
    margin-right: -${({ theme }) => theme.structure.main.margin.horizontal.pc}px;
  }
  ${media.siteBreakpointOrMore} {
    width: 100%;
    padding-right: initial;
    margin-right: initial;
  }
`;

export default Page;
