import styled from '@emotion/styled';
import { motion, type AnimationProps, type MotionProps } from 'framer-motion';
import { type FC, type ReactNode } from 'react';

import { Page as CommonPage, ScreenReaderText } from '@/components/common';
import { Anchor } from '@/components/common/atoms';
import { Entry as HomeEntry } from '@/components/home';
import { media } from '@/styles';

type Props = {
  className?: string;
  children?: ReactNode;
  name: string;
  to: string;
  label: string;
  initialStyle: MotionProps['initial'];
  animationStyle: AnimationProps['animate'];
  isSingle?: boolean;
};

export const Page: FC<Props> = ({
  className,
  children,
  name,
  to,
  label,
  initialStyle,
  animationStyle,
  isSingle,
}) => {
  const isHome = name === 'home';
  const isCurrent = children !== undefined;
  return (
    <Wrapper
      className={`${className ?? ''} ${
        isCurrent ? 'current' : ''
      } page-${name}`}
      layoutId={`page-${name}`}
      initial={initialStyle ?? false}
      animate={animationStyle}
      transition={{
        ease: 'circOut',
        duration: 0.28,
      }}
    >
      {isHome && (
        <StyledAnchorHome to={to}>
          <ScreenReaderText>{label}</ScreenReaderText>
          <HomeEntry />
        </StyledAnchorHome>
      )}
      {!isHome && (
        <CommonPage
          name={name}
          to={to}
          label={label}
          titleTag={isCurrent && !isSingle ? 'h1' : 'p'}
          isCurrent={isCurrent}
        >
          {children}
        </CommonPage>
      )}
    </Wrapper>
  );
};

const StyledAnchorHome = styled(Anchor)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${media.siteBreakpointOrMore} {
    /* NOTE:
    homeとaboutは、headerWidth.pc * 5
    他のページは、headerWidth.pc * 4
     */
    width: calc(
      100% - ${({ theme }) => theme.structure.page.headerWidth.pc * 5}px
    );
  }
`;

const Wrapper = styled(motion.div)`
  position: relative;
  width: calc(
    100% + ${({ theme }) => theme.structure.main.margin.horizontal.pc}px
  );
  overflow: hidden;
  height: 100%;
  &:not(.current) {
    &.page-home {
      height: 100%;
    }
  }
  &.page-about {
    opacity: 0;
    &.current {
      opacity: 1;
    }
  }
`;

export default Page;
