import styled from '@emotion/styled';
import { type FC } from 'react';

import { Anchor } from '@/components/common/atoms';
import { media } from '@/styles';

type Props = {
  className?: string;
  children: React.ReactNode;
  to: string;
};

export const BigAnchor: FC<Props> = ({ className, children, to }) => (
  <Wrapper className={className ?? ''} to={to}>
    {children}
  </Wrapper>
);

const Wrapper = styled(Anchor)`
  display: block;
  width: 100%;
  color: white;
  background-color: black;
  text-decoration: none;
  border: 1px solid black;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.mediumLarge.ja};
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  &:hover,
  &:focus-visible {
    color: black;
    background-color: white;
  }
  ${media.ipadVerticalOrMore} {
    font-size: ${({ theme }) => theme.font.size.large.ja};
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
`;

export default BigAnchor;
