import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Next } from './next';
import { PagesInBetween } from './pages-in-between';
import { Previous } from './previous';

type Props = Queries.PaginationFragment & {
  className?: string;
  archivePath: string;
};

export const fragment = graphql`
  fragment Pagination on PageInfo {
    hasPreviousPage
    hasNextPage
    currentPage
    pageCount
  }
`;

export const Pagination: React.VFC<Props> = ({
  className,
  hasPreviousPage,
  hasNextPage,
  currentPage,
  pageCount,
  archivePath,
}) => {
  if (!hasPreviousPage && !hasNextPage) {
    return null;
  }
  return (
    <Wrapper className={className ?? ''}>
      <Previous
        hasPreviousPage={hasPreviousPage}
        currentPage={currentPage}
        archivePath={archivePath}
      />
      <PagesInBetween
        currentPage={currentPage}
        pageCount={pageCount}
        archivePath={archivePath}
      />
      <Next
        hasNextPage={hasNextPage}
        currentPage={currentPage}
        archivePath={archivePath}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: -1em;
  > * {
    display: inline-block;
    margin-left: 1em;
  }

  > .current {
    text-decoration: underline;
  }

  > a {
    text-decoration: none;
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
`;

export default Pagination;
